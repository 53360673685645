import React, { useState } from "react";
import SEO from "../components/seo";
import { Container } from "reactstrap";
import { Table, TableHeader, TableBody, TableRow, TableCell } from "grommet";
import styled from "styled-components";
import PageTitle from "../components/page-title";
import HeroBanner from "../components/home/heroBanner/HeroBanner";
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"

const StyledTable = styled(Table)`
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
  border-collapse: collapse;
`;

const StyledTableHead = styled(TableCell)`
  background: #daeef3;
  font-weight: bold;
  padding: 15px;
  text-align: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  border-bottom: 0px solid #003d80;
  padding-bottom: 0px;
  &:hover {
    text-decoration: underline;
    color: #003d80;
  }
`;

const DropdownContent = styled.div`
  margin-top: 10px;
  padding-left: 25px;
  line-height: 1.4;
  background: #f9fafb;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  max-height: ${({ isOpen }) => (isOpen ? "10000px" : "0")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  overflow: hidden;
`;

const DropdownSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <TitleContainer onClick={() => setIsOpen(!isOpen)}>
                <span>{title}</span>
                <span>{isOpen ? "⮝" : "⮟"}</span>
            </TitleContainer>
            <DropdownContent isOpen={isOpen}>{children}</DropdownContent>
        </div>
    );
};

const ForLearners = () => {
    return (
        <>
            <SEO
                title="BIM & CAD Training Center | Ace Industrial Academy"
                description="Ace Industrial Academy is a leading training center in Singapore for IT courses, BIM courses, 3D CAD courses, 4D courses, software courses, building information modeling courses and more."
            />
            <TitleBannerEnquiry
                title="For Learners"
                description=""
                withOnlyDescription
                hasPlainBanner
            />

            <Container fluid className="pt-6" style={{ backgroundColor: '#eef3f8' }}>
                <Container className="pt-4">
                    <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                        <Container className="pt-4">
                            <h1 className="trainingTitle" style={{ textAlign: 'left', marginLeft: '-40px' }}></h1>
                            <h3 className="trainingContent mt-3" style={{ textAlign: 'left', marginLeft: '-20px' }}>Course Administrative Information </h3><br />
                            <p className="trainingContent">
                                The Terms & Conditions listed below are applicable for all Ace Industrial Academy courses. <br /><br />
                                Ace Industrial Academy reserves the right to make changes or modifications to these Terms and Conditions at any time without prior notice, as it deems appropriate.<br /><br />
                                We place great importance on ensuring that learners get the full benefit from our courses here at Ace Industrial Academy. Therefore, we expect learners to take responsibility for their learning journey. <br /><br />
                            </p><br />
                        </Container>
                    </Container>
                    <DropdownSection title="Pre-Course Administration">
                        <DropdownSection title="General">
                            <p>
                                All interested applicants must comply with the Institute for Ace Industrial Academy’s entry requirements and application procedures, which may include the submission of supporting documents, attending a pre-enrolment screening, or both. <br /><br />
                                Workstations will be provided during the training course.
                            </p>
                        </DropdownSection>
                        <DropdownSection title="Fees and Funding Eligibility">
                            <p>
                                The indicated fee payable (inclusive of GST) will cover the training sessions and the course assessment, and it will also take into account all eligible grants/subsidies.<br /><br />
                                Learners enrolled in WSQ courses are obliged to meet the attendance requirements and achieve the competencies. Otherwise, Ace Industrial Academy reserves the right to recover the course grants/subsidies disbursed.<br /><br />
                                Please click <a href="https://www.aia.edu.sg/funding">here</a> for more information on available training grants. To check further on your eligibility for course fee funding, please approach the Ace Industrial Academy Pte Ltd training department <a href="mailto:aia@training.edu.sg">aia@training.edu.sg</a><br /><br />
                            </p>
                        </DropdownSection>
                        <DropdownSection title="Registration and Payment">
                            <p>
                                <strong>1. GST</strong><br /><br />
                                If the subsidy/grant is awarded to the trainee to help defray the cost of training fees, the GST registered training provider should charge and account for GST on the gross training fees before deducting the subsidies. This is notwithstanding that the subsidies/grants may be credited to the training provider directly. To find out more,<a href="https://www.iras.gov.sg/taxes/goods-services-tax-(gst)/charging-gst-(output-tax)/common-scenarios---do-i-charge-gst/provision-of-subsidized-services"> IRAS | Provision of subsidized services</a><br /><br />
                                <div className="trainingContent">
                                    <strong>2. Self-Sponsored / Company-Sponsored Individuals</strong>
                                    <ul style={{ paddingLeft: '20px' }}><br />
                                        <li>✓ Complete the course fee payment via Paynow and/or SkillsFuture Credit (SFC) / PSEA upon tax invoice provided.</li>
                                        <li>✓ Payment must be made prior to the course commencement date.</li>
                                        <li>✓ Individuals may seek reimbursement from their company.</li>
                                    </ul>
                                </div>
                            </p>
                        </DropdownSection>
                        <DropdownSection title="Withdrawal / Deferment and Refund">
                            <StyledTable>
                                <TableHeader>
                                    <TableRow>
                                        <StyledTableHead>
                                            Cancellation/Withdrawal Notice
                                        </StyledTableHead>
                                        <StyledTableHead>
                                            Refund Policy
                                        </StyledTableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    <TableRow>
                                        <TableCell border="all" align="center">
                                            More than 10 working days before course commencement date
                                        </TableCell>
                                        <TableCell border="all" align="center">
                                            Full refund of course fees paid
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell border="all" align="center">
                                            4-10 working days before course commencement date
                                        </TableCell>
                                        <TableCell border="all" align="center">
                                            Refund of course fees paid with an admin charge of $100
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell border="all" align="center">
                                            3 or less working days before course commencement date
                                        </TableCell>
                                        <TableCell border="all" align="center">
                                            No refund
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </StyledTable><br />
                            <p>
                                <em>Please communicate your withdrawal / deferment request to <a href="mailto:training@aia.edu.sg">training@aia.edu.sg</a></em>
                            </p>
                        </DropdownSection>
                        <DropdownSection title="Attendance">
                            <p>
                                <div className="trainingContent">
                                    <p>
                                        All Singaporean Citizens/Permanent Residents attending SkillsFuture Singapore (SSG)'s funded courses are required to register, set up their Singpass account, and download the latest version of the Singpass App prior to course commencement.<br /><br />
                                        Learners without Singpass should register for a Singpass account immediately. For more information on setting up and accessing your Singpass account, please visit the Singpass website here.<br /><br />
                                    </p>
                                    <ul>
                                        <li>
                                            Learners should be present in class (classroom and online) 15 minutes before the scheduled lesson time on the actual day and have the following ready:
                                            <ul style={{ paddingLeft: '20px' }}><br />
                                                <li>1. A smart device (e.g., smartphone or iPad) with a functioning camera.</li>
                                                <li>2. The Singpass mobile app installed on the smart device. (Note: Please log out from the other devices if your Singpass app is installed on multiple devices. Users can only utilize the Singpass mobile app from one device at a time.)</li>
                                                <li>3. Login credentials for the Singpass App (i.e., username and password, facial recognition, or fingerprint scanning).</li>
                                                <li>4. We strongly encourage full attendance for all classes, and SSG-funded courses require a minimum attendance of 75%.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div><br />
                            </p>
                        </DropdownSection>
                        <DropdownSection title="Class Cancellation">
                            <p>
                                Ace Industrial Academy reserves the right to cancel, postpone or make changes to any courses should unforeseen circumstances occur. Learners will be duly notified, and the full fees will be refunded, where applicable.
                            </p>
                        </DropdownSection>
                        <DropdownSection title="Personal Data Protection">
                            <p>
                                Please refer to the Privacy Policy to understand the purposes for which Ace Industrial Academy collects, uses and discloses your Personal Data.
                            </p>
                        </DropdownSection><br />
                    </DropdownSection>
                    <DropdownSection title="In-Course Administration">
                        <DropdownSection title="Re-Assessment">
                            <p>
                                Learners accorded with the 'Not-Yet-Competent' (NYC) outcome will be contacted by Ace Industrial Academy’s training coordinator to be scheduled a re-assessment.<br /><br />
                                Failing the re-assessment attempt, Ace Industrial Academy reserves the right to recover the course grants/subsidies disbursed.
                            </p>
                        </DropdownSection>
                        <DropdownSection title="Learner Feedback">
                            <p>
                                Ace Industrial Academy, we are committed to creating a positive learning environment that encourages active learning.<br /><br />
                                Trainers will flash a QR code at the end of the training course for feedback.<br /><br />
                                Serious misconduct allegations against a Trainer/Assessor/Coach or staff warrant an investigation that may lead to the appropriate counselling and/or disciplinary action against the accused.
                            </p>
                        </DropdownSection>
                        <DropdownSection title="Institute Public Disengagement Policy">
                            <p>
                                At Ace Industrial Academy, we strive to provide exceptional service and support to ensure your learning experience is enriching and rewarding. <br /><br />
                                We expect all learners to treat our staff members with respect, courtesy, and professionalism. We understand that you may have questions or concerns or require assistance, and we encourage open and constructive communication. However, abusive behavior will not be tolerated under any circumstances.<br /><br />
                                We appreciate your understanding and cooperation in helping us maintain a positive and respectful environment at Ace Industrial Academy.
                            </p>
                        </DropdownSection><br />
                    </DropdownSection>
                    <DropdownSection title="Post-Course Administration">
                        <DropdownSection title="Statement of Attainment">
                            <p>
                                All WSQ certificates are issued by SkillsFuture Singapore (SSG) through MyskillsFuture Portal. Your SOAs, transcript or full qualification will be available 4 to 6 weeks upon the completion of the module or final assessment.
                            </p>
                        </DropdownSection><br />
                    </DropdownSection><br /><br />
                </Container>
            </Container>
            <Container className="pt-4">
                <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                    <h4 className="trainingContent mt-3" style={{ textAlign: 'left', marginLeft: '-20px' }}><em>Technical Support</em></h4><br />
                    <p className="trainingContent" >
                        <em>If you encounter registration issues, you can contact our training coordinator at <a href="mailto:training@aia.edu.sg">training@aia.edu.sg</a></em><br /><br />
                    </p><br />
                </Container>
            </Container >
        </>
    );
};
export default ForLearners;